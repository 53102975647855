import React from "react"

import Checkbox from "../../../../design-system/checkbox"
import Header from "../../../../design-system/header"
import UniversalLink from "../../../../design-system/universal-link"
import { BucketValidCombination } from "../../../../experimentation/types"
import { Pathname } from "../../../../experimentation/types/base-paths"
import {
  getCleanPathname,
  getExperimentIdsByPathname,
  getFullExperimentPathFromCombination,
} from "../../../../experimentation/utils"
import { isBrowser } from "../../../../utils/browser"
import { classNames } from "../../../../utils/class-names"
import ExperimentBucketSelectorDropdown from "./experiment-bucket-selector-dropdown"
import * as styles from "./index.module.scss"

export type Props<T extends Pathname> = {
  basePath: T
  combination: BucketValidCombination<T>
}

function ExperimentVariantLinks<T extends Pathname>({
  basePath,
  combination,
}: Props<T>) {
  const experimentIds = getExperimentIdsByPathname(basePath)
  const { pathname: currentPath = basePath, hash: windowHash = "" } =
    isBrowser() ? window.location : {}
  const isRealBuckets =
    getCleanPathname(basePath) === getCleanPathname(currentPath)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Header variant="4" as="h3">
          🧪 Select variant buckets
        </Header>
        <UniversalLink
          to={
            (isRealBuckets
              ? getFullExperimentPathFromCombination(basePath, combination)
              : basePath) + windowHash
          }
        >
          <Checkbox
            id="dynamic-url"
            label="Use real buckets"
            defaultChecked={isRealBuckets}
          />
        </UniversalLink>
      </div>
      <fieldset
        disabled={isRealBuckets}
        className={classNames(isRealBuckets && styles.fieldsetDisabled)}
      >
        {experimentIds.map(experimentId => (
          <ExperimentBucketSelectorDropdown
            key={experimentId}
            combination={combination}
            isRealBuckets={isRealBuckets}
            experimentId={experimentId}
            basePath={basePath}
            windowHash={windowHash}
          />
        ))}
      </fieldset>
    </div>
  )
}

export default ExperimentVariantLinks
