import React, { useEffect, useMemo } from "react"

import { BucketValidCombination } from "../../../experimentation/types"
import { Pathname } from "../../../experimentation/types/base-paths"
import { classNames } from "../../../utils/class-names"
import * as styles from "./index.module.scss"

export interface Props<T extends Pathname> {
  combination: BucketValidCombination<T>
  userIdFromCookie: string
  isMini?: boolean
}

type Log = {
  time: string
  event: string
}

function BucketLogs<T extends Pathname>({
  combination,
  isMini = false,
  userIdFromCookie,
}: Props<T>) {
  const [cookieValueLogs, setCookieValueLogs] = React.useState<Log[]>([])
  const combinationRef = React.useRef<typeof combination>()
  const userIdRef = React.useRef<string | null>(null)
  const logsContainerRef = React.useRef<HTMLDivElement>(null)

  const combinationEntries = useMemo(
    () =>
      Object.entries(combination) as [
        keyof BucketValidCombination<T>,
        string | undefined,
      ][],
    [combination],
  )

  useEffect(() => {
    if (userIdFromCookie !== userIdRef.current) {
      setCookieValueLogs(prev => [
        ...prev,
        {
          time: new Date().toLocaleTimeString(),
          event: `User Id updated to "${userIdFromCookie}"`,
        },
      ])
      userIdRef.current = userIdFromCookie
    }
  }, [userIdFromCookie])

  useEffect(() => {
    const logsToAdd: Log[] = []
    combinationEntries.forEach(([experimentId, bucket]) => {
      if (bucket !== combinationRef.current?.[experimentId]) {
        logsToAdd.push({
          time: new Date().toLocaleTimeString(),
          event: `Experiment "${experimentId}" updated to "${bucket}"`,
        })
      }
    })

    if (logsToAdd.length) {
      setCookieValueLogs(prev => [...prev, ...logsToAdd])
      setTimeout(() => {
        logsContainerRef.current?.scrollTo({
          top: logsContainerRef.current.scrollHeight + 1000,
        })
      })
    }

    combinationRef.current = combination
  }, [combination, combinationEntries])

  return (
    <div
      className={classNames(styles.container, isMini && styles.containerMini)}
      ref={logsContainerRef}
    >
      <code>
        <p>These are the logs for bucket changes:</p>
        {cookieValueLogs.map(({ time, event }) => (
          <p key={time + event} data-chromatic="ignore">
            {time} - {event}
          </p>
        ))}
        <br />
        <p>
          <strong>No further logs</strong>
        </p>
      </code>
    </div>
  )
}

export default BucketLogs
