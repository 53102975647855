import React from "react"
import Cookies from "universal-cookie"

import Header from "../../../design-system/header"
import PaperExpandable from "../../../design-system/paper-expandable"
import Paragraph from "../../../design-system/paragraph"
import {
  EXPERIMENT_UID_COOKIE,
  getExperimentBucketCookieKey,
} from "../../../experimentation/constants"
import { useExperimentContext } from "../../../experimentation/contexts/experiment-context"
import { useStatsigInitialized } from "../../../experimentation/contexts/statsig-provider"
import { BucketValidCombination } from "../../../experimentation/types"
import { Pathname } from "../../../experimentation/types/base-paths"
import { getPathPartFromCombination } from "../../../experimentation/utils"
import SectionLayout from "../../section-layout"
import BucketLogs from "../bucket-logs"
import ColoredBlockForExperimentTesting from "../colored-block-for-experiment-testing"
import { BUCKET_BACKGROUND_COLORS, IS_DYNAMIC_BACKGROUND_COLORS } from "../data"
import ExperimentVariantLinks from "../experiment-statistics-mini/experiment-variant-links"
import ObjectEntriesDisplay from "../object-entries-display"
import ResetExperimentUidButton from "../reset-experiment-uid-button"
import ExperimentUserIdDisplay from "./experiment-user-id-display"
import * as styles from "./index.module.scss"

export type ExperimentStatisticsProps<T extends Pathname> = {
  combination: BucketValidCombination<T>
  userIdFromCookie?: string
  basePath: T
  isDynamic: boolean
}

function ExperimentStatistics<T extends Pathname>({
  basePath,
  combination,
  userIdFromCookie,
  isDynamic,
}: ExperimentStatisticsProps<T>) {
  const cookies = new Cookies()

  const experimentIds = Object.keys(
    combination,
  ) as (keyof BucketValidCombination<T>)[]

  const cookieMap = experimentIds.reduce<Record<string, string | undefined>>(
    (acc, experimentId) => {
      const bucketCookieKey = getExperimentBucketCookieKey(experimentId)
      acc[bucketCookieKey] = cookies.get(bucketCookieKey)
      return acc
    },
    {},
  )

  const parameters = useExperimentContext(basePath)
  const stringCombination = getPathPartFromCombination(basePath, combination)
  const { isInitialized } = useStatsigInitialized()

  return (
    <SectionLayout
      title="Experiment buckets:"
      subtitle={stringCombination.replace(/;/g, "\n").replace(/=/g, " = ")}
    >
      <div className={styles.firstRow}>
        <div>
          This block's color should not flicker. It should be for each bucket:
          <div className={styles.coloredBlocks}>
            {experimentIds.map(experimentId => {
              const bucketOrError = combination[experimentId]
              return (
                <ColoredBlockForExperimentTesting
                  key={experimentId}
                  value={bucketOrError}
                  colorMap={BUCKET_BACKGROUND_COLORS}
                  defaultKey="Bucket not on the list"
                  sizeInPx={200}
                  blocksPurposeDescription={`Bucket for ${experimentId}: ${bucketOrError}`}
                />
              )
            })}
          </div>
        </div>
        <div className={styles.textContainer}>
          <div style={{ marginBottom: "2rem" }}>
            <ResetExperimentUidButton basePath={basePath} />
          </div>
          <PaperExpandable
            externalIsExpanded
            chevronSmall
            paddingSmall
            trigger={
              <Header variant="4" as="h3">
                🍪 Cookies:
              </Header>
            }
          >
            <ObjectEntriesDisplay
              object={{
                [EXPERIMENT_UID_COOKIE]: userIdFromCookie,
                ...cookieMap,
              }}
            />
          </PaperExpandable>
          <br />
          <PaperExpandable
            chevronSmall
            paddingSmall
            trigger={
              <Header variant="4" as="h3">
                Parameters:
              </Header>
            }
          >
            <ObjectEntriesDisplay
              object={parameters ?? {}}
              data-testid="parameters"
            />
          </PaperExpandable>
          <Paragraph variant="1" style={{ marginTop: "1rem" }}>
            <strong>Client-side statsig is initialized:</strong>{" "}
            {isInitialized.toString()}
          </Paragraph>
          <ExperimentUserIdDisplay
            userIdFromCookie={userIdFromCookie}
            variant="1"
          />
        </div>
        <ColoredBlockForExperimentTesting
          value={
            isDynamic
              ? "Client side JS is rendering the page"
              : "Page is statically generated"
          }
          colorMap={IS_DYNAMIC_BACKGROUND_COLORS}
          defaultKey="Page is statically generated"
          sizeInPx={300}
          blocksPurposeDescription="This should flicker if the page was accessed through an external link/reloaded. The colors represent:"
          data-testid="is-dynamic-block"
        />
        <ExperimentVariantLinks basePath={basePath} combination={combination} />
      </div>
      <BucketLogs
        combination={combination}
        userIdFromCookie={userIdFromCookie ?? "No user id"}
      />
    </SectionLayout>
  )
}

export default ExperimentStatistics
