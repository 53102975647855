/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react"

import Button from "../../../design-system/button"
import { Pathname } from "../../../experimentation/types/base-paths"
import { ExperimentStatisticsProps } from "../experiment-statistics"
import ExperimentUserIdDisplay from "../experiment-statistics/experiment-user-id-display"
import ResetExperimentUidButton from "../reset-experiment-uid-button"
import ExperimentVariantLinks from "./experiment-variant-links"
import * as styles from "./index.module.scss"

export type Props<T extends Pathname> = {
  onClose: () => void
  onClick: () => void
} & ExperimentStatisticsProps<T>

function ExperimentStatisticsMini<T extends Pathname>({
  onClose,
  onClick,
  combination,
  basePath,
  userIdFromCookie,
}: Props<T>) {
  return (
    <div className={`${styles.container} ${styles.mini}`}>
      <button
        className={styles.closeButton}
        onClick={() => onClose()}
        type="button"
      >
        x
      </button>
      <ExperimentVariantLinks basePath={basePath} combination={combination} />
      <div className={styles.bottomContainer}>
        <ExperimentUserIdDisplay userIdFromCookie={userIdFromCookie} />
        <div className={styles.buttonsContainer}>
          <ResetExperimentUidButton basePath={basePath} />
          <Button onClick={onClick}>Open full stats</Button>
        </div>
      </div>
    </div>
  )
}

export default ExperimentStatisticsMini
