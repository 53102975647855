import React from "react"
import Statsig from "statsig-js"
import Cookies from "universal-cookie"

import Button from "../../../design-system/button"
import {
  EXPERIMENT_UID_COOKIE,
  MIDDLEWARE_EXPERIMENT_IDS_COOKIE,
} from "../../../experimentation/constants"
import { useStatsigInitialized } from "../../../experimentation/contexts/statsig-provider"
import { getStatsigUser } from "../../../experimentation/middleware/statsig-user-custom-data"
import { Pathname } from "../../../experimentation/types/base-paths"
import { generateId } from "../../../utils/random-id-generator"
import * as styles from "./index.module.scss"

const COOKIE_OPTIONS = {
  path: "/",
}

type Props = {
  basePath: Pathname
}

function ResetExperimentUidButton({ basePath }: Props) {
  const cookies = new Cookies()

  const {
    isInitialized: statsigIsInitialized,
    setIsInitialized: setStatsigIsInitialized,
  } = useStatsigInitialized()

  return (
    <div className={styles.container}>
      <Button
        disabled={!statsigIsInitialized}
        loading={!statsigIsInitialized}
        onClick={() => {
          const newUserId = generateId()
          setStatsigIsInitialized?.(false)
          Statsig.flushEvents()
          Statsig.updateUser(getStatsigUser(() => newUserId)).then(() => {
            cookies.set(EXPERIMENT_UID_COOKIE, newUserId, COOKIE_OPTIONS)
            cookies.remove(MIDDLEWARE_EXPERIMENT_IDS_COOKIE, COOKIE_OPTIONS)
            setStatsigIsInitialized?.(true)
          })
        }}
      >
        🍪 Reset UId
      </Button>
      <Button
        onClick={() => {
          cookies.remove(EXPERIMENT_UID_COOKIE, COOKIE_OPTIONS)
          window.location.replace(basePath)
        }}
      >
        Clear + Refresh
      </Button>
    </div>
  )
}

export default ResetExperimentUidButton
