import React, { useLayoutEffect } from "react"

import Paragraph from "../../../../design-system/paragraph"

interface Props {
  userIdFromCookie?: string
  variant?: "1" | "3"
}

function ExperimentUserIdDisplay({ userIdFromCookie, variant = "3" }: Props) {
  const [showUid, setShowUid] = React.useState(false)
  useLayoutEffect(() => {
    // Since staticly generated page doesn't have the cookie, we need to show it with an effect, to avoid hydration error
    setShowUid(true)
  }, [])

  if (!showUid) {
    return null
  }

  return (
    <Paragraph variant={variant} data-testid="experiment-user-id-display">
      <strong>User Id: </strong> {userIdFromCookie}
    </Paragraph>
  )
}

export default ExperimentUserIdDisplay
