import * as React from "react"

import Label from "../label"
import ParagraphDeprecated from "../paragraph-deprecated"
import * as styles from "./index.module.scss"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode
  id: string
  area?: boolean
}

const Checkbox: React.FC<Props> = ({
  label,
  id,
  checked,
  area = false,
  children,
  className,
  ...otherProps
}) => {
  return (
    <Label
      className={`${styles.label} ${area && styles.area} ${
        area && checked && styles.areaChecked
      } ${className}`}
    >
      <input
        type="checkbox"
        id={id}
        className={styles.checkbox}
        checked={checked}
        {...otherProps}
      />
      <ParagraphDeprecated variant="3" as="span" className={styles.paragraph}>
        {label}
      </ParagraphDeprecated>
      {children && (
        <div style={{ margin: "8px 0 0 24px", flexBasis: "100%" }}>
          {children}
        </div>
      )}
    </Label>
  )
}

export default Checkbox
